import React, { useEffect } from "react";
import MapLatLng from "./MapLatLng";

const CreateRoute = () => {
  useEffect(() => {
    document.title = "Registrar Ruta";
  }, []);

  return (
    <div>
      <MapLatLng />
    </div>
  );
};

export default CreateRoute;
