import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import { VALIDATOR_REQUIRED, VALIDATOR_EMAIL } from "shared/utils/validator"
import axios from "axios"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"

const EditDriver = (props) => {
  const history = useHistory()
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [driver, setDriver] = useState(null)
  const [companies, setCompanies] = useState([])
  const [routes, setRoutes] = useState([])
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm({
    name: {
      value: "",
      isValid: false
    },
    email: {
      value: "",
      isValid: false
    }
  })

  useEffect(() => {
    document.title = "Editar Conductor"
    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      getCompanies()
    }
    getDriver()
  }, [auth.token])

  const getDriver = async () => {
    setIsLoading(true)
    const response = await axios({
      headers: {
        Authorization: `Bearer ${auth.token}`
      },
      baseURL: `${process.env.REACT_APP_API_URL}/drivers/${props.match.params.id}`,
      method: "GET"
    })

    setDriver(response.data.driver)
    setIsLoading(false)

    getRoutes(response.data.driver.company.id)
  }

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: url,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getRoutes = async (companyId) => {
    setIsLoading(true)

    const endpoint = `/routes/company/${companyId}`

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}${endpoint}`,
        method: "GET"
      })

      if (response.status === 200) {
        setRoutes(response.data.routes)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.push("/")
      }
      setError(err.response.data.error)
    }
    setIsLoading(false)
  }

  const updateDriver = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/drivers/${props.match.params.id}`,
        method: "PUT",
        data: {
          name: formState.inputs.name.value,
          lastname: formState.inputs.lastname.value,
          registration: formState.inputs.registration.value,
          license: formState.inputs.license.value,
          company_id:
            auth.user.role !== "company"
              ? formState.inputs.company.value
              : auth.user.company.id,
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
          routeId: formState.inputs.route.value
        }
      })

      setIsLoading(false)

      if (response.status === 201) {
        history.replace("/drivers")
      }
    } catch (err) {
      setIsLoading(false)
      setError(err.response.data.message || "Algo salió mal")
    }
  }

  return (
    <div>
      <h1 className="title">Editar Conductor</h1>
      <div className="actions buttons">
        <button className="button is-success" onClick={updateDriver}>
          Actualizar
        </button>
        <button onClick={history.goBack} className="button is-danger">
          Cancelar
        </button>
      </div>
      {error && (
        <div className="notification is-danger is-light">Error: {error}</div>
      )}
      {isLoading && <Loader asOverlay />}
      <div className="card-mibus">
        {driver && (
          <div>
            <div className="columns">
              <div className="column">
                {companies &&
                  auth.user &&
                  auth.user.role !== "company" &&
                  (driver.company !== null ? (
                    <div className="form-group">
                      <div style={{ display: "none" }}>
                        {JSON.stringify(driver.company)}
                      </div>
                      <Input
                        id="company"
                        placeholder="Empresa"
                        label="Empresa"
                        className="form-control"
                        value={driver.company.id}
                        element="select"
                        onInput={inputHandler}
                        validators={[VALIDATOR_REQUIRED()]}
                        errorText="Este campo es requerido"
                      >
                        <option value="">Seleccionar</option>
                        {companies.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          )
                        })}
                      </Input>
                    </div>
                  ) : (
                    <div className="form-group">
                      <Input
                        id="company"
                        placeholder="Empresa"
                        label="Empresa"
                        className="form-control"
                        element="select"
                        onInput={inputHandler}
                        validators={[VALIDATOR_REQUIRED()]}
                        errorText="Este campo es requerido"
                      >
                        <option value="">Seleccionar</option>
                        {companies.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          )
                        })}
                      </Input>
                    </div>
                  ))}
                <div className="form-group">
                  <Input
                    type="text"
                    id="name"
                    value={driver.name}
                    placeholder="Nombre"
                    label="Nombre"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="text"
                    id="lastname"
                    value={driver.lastname}
                    placeholder="Apellido"
                    label="Apellido"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="text"
                    id="registration"
                    value={driver.registration}
                    placeholder="Matrícula"
                    label="Matrícula"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="text"
                    id="license"
                    value={driver.licenseType}
                    placeholder="Licencia"
                    label="Licencia"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED()]}
                    errorText="Este campo es requerido"
                  />
                </div>
              </div>
              <div className="column">
                <div className="form-group">
                  <Input
                    type="text"
                    id="email"
                    value={driver.email}
                    placeholder="Correo electrónico"
                    label="Correo electrónico"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                    errorText="Este campo debe ser un correo electrónico válido"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="password"
                    id="password"
                    element="password"
                    placeholder="Contraseña (Solo llenar para cambiar la anterior)"
                    label="Contraseña (Solo llenar para cambiar la anterior)"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[]}
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="password"
                    id="repassword"
                    element="password"
                    placeholder="Confirmar Contraseña"
                    label="Confirmar contraseña"
                    className="form-control"
                    onInput={inputHandler}
                    validators={[]}
                  />
                </div>
                {routes && (
                  <div className="form-group">
                    <Input
                      id="route"
                      placeholder="Ruta"
                      label="Ruta"
                      className="form-control"
                      element="select"
                      value={driver.route}
                      onInput={inputHandler}
                      validators={[]}
                    >
                      <option value="">Seleccionar</option>
                      {routes.map((route) => {
                        return (
                          <option key={route.id} value={route.id}>
                            {route.name}
                          </option>
                        )
                      })}
                    </Input>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditDriver
