import React, { useRef, useState } from "react";
import Icon from "@mdi/react";

import "./ExcelUpload.css";
import { mdiUpload } from "@mdi/js";

const ExcelUpload = (props) => {
  const [file, setFile] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const filePickerRef = useRef();

  const pickHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    props.onInput(props.id, pickedFile, isValid);
  };

  const pickExcelHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className="form-control">
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept=".xlsx"
        onChange={pickHandler}
      />
      <div className="excel-upload">
        <div className="desc">
          {file && file.name}
          {!file && !props.value && <span>Escoge un archivo</span>}
          <button className="btn-pick" type="button" onClick={pickExcelHandler}>
            <Icon path={mdiUpload} size={1} />
          </button>
        </div>
      </div>
      {!isValid && <span> {props.errorText} </span>}
    </div>
  );
};

export default ExcelUpload;
