import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function SimpleBarChart({ routes }) {
  const labels = routes.map((route) => route.name)

  // Calculate cost per employee or use route cost if number of employees is 0
  const costPerEmployee = routes.map((route) =>
    route.used > 0 ? route.cost / route.used : route.cost
  )

  const options = {
    responsive: true
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Costo en MXN",
        data: costPerEmployee,
        backgroundColor: "rgb(99 255 127 / 50%)"
      }
    ]
  }

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">Costo por empleado</p>
        <button className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div className="card-content">
        <div className="content">
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  )
}

export default SimpleBarChart
