import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import axios from "axios"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"
import {
  VALIDATOR_REQUIRED,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH
} from "shared/utils/validator"

const CreateUser = (props) => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [businessUser, setBusinessUser] = useState([])
  const [companies, setCompanies] = useState([])
  const [rol, setRol] = useState("")
  const [formState, inputHandler] = useForm({
    name: {
      value: "",
      isValid: false
    },
    lastname: {
      value: "",
      isValid: false
    },
    role: {
      value: "",
      isValid: false
    },
    company: {
      value: "",
      isValid: false
    },
    email: {
      value: "",
      isValid: false
    },
    password: {
      value: "",
      isValid: false
    },
    repassword: {
      value: "",
      isValid: false
    }
  })

  useEffect(() => {
    document.title = "Registrar Usuario"
    if (!auth.token) {
      return
    }

    if (auth.user.role === "business") {
      setRol("company")
    }

    if (auth.user.role === "company") {
      history.replace("/panel")
    } else if (auth.user.role === "admin") {
      getBusinessUser()
    } else {
      getCompanies()
    }
  }, [auth.token])

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: url,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getBusinessUser = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/users/type/business`,
        method: "GET"
      })

      if (response.status === 200) {
        setBusinessUser(response.data.users)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }

    setIsLoading(false)
  }

  const getCompaniesByUser = async (parent) => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/companies/business/${parent}`,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getCompaniesByUserParent = (user) => {
    if (user !== "") {
      getCompaniesByUser(user)
    }
  }

  const submitUser = (event) => {
    event.preventDefault()
    setIsLoading(true)

    const userParent = rol === "company" ? formState.inputs.value : null

    const user = {
      name: formState.inputs.name.value,
      lastname: formState.inputs.lastname.value,
      role:
        auth.user.role === "business" ? "company" : formState.inputs.role.value,
      userParent: auth.user.role === "admin" ? userParent : auth.user.id,
      company:
        auth.user.role === "admin" || auth.user.role === "business"
          ? formState.inputs.company.value
          : user.company,
      email: formState.inputs.email.value,
      password: formState.inputs.password.value,
      repassword: formState.inputs.repassword.value
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/signup`, user, {
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      })
      .then((res) => {
        history.push("/users")
      })
      .catch((err) => {
        const error = err.response.data.message.replace("ValidationError: ", "")
        setError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div>
      {isLoading && <Loader asOverlay />}
      <h1 className="title">Registrar Usuario</h1>
      <div className="actions">
        <button className="button" onClick={submitUser}>
          Registrar
        </button>
        <button className="button is-danger" onClick={history.goBack}>
          Cancelar
        </button>
      </div>
      {error && <div className="notification is-danger is-light">{error}</div>}
      <div className="card-mibus">
        <div className="columns">
          <div className="column">
            <div className="form-group">
              <Input
                type="text"
                id="name"
                label="Nombre"
                placeholder="Nombre"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="Este campo es requerido"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="lastname"
                label="Apellido"
                placeholder="Apellido"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="Este campo es requerido"
              />
            </div>
            {auth && auth.user.role !== "business" && (
              <>
                <div className="form-group">
                  <Input
                    type="select"
                    id="role"
                    label="Rol"
                    element="select"
                    onInput={inputHandler}
                    callFunction={setRol}
                    errorText="El rol es obligatorio"
                    validators={[VALIDATOR_REQUIRED()]}
                  >
                    <option value="">Seleccionar Rol</option>
                    {auth.user.role === "admin" && (
                      <>
                        <option value="admin">Administrador</option>
                        <option value="business">Transportista</option>
                        <option value="company">Compañía</option>
                      </>
                    )}
                  </Input>
                </div>
              </>
            )}
            {rol === "company" && auth.user.role !== "business" && (
              <div className="form-group">
                <Input
                  type="select"
                  id="userParent"
                  label="Usuario Padre"
                  element="select"
                  onInput={inputHandler}
                  callFunction={getCompaniesByUserParent}
                  errorText="El padre es obligatorio para los usuarios de tipo compañía"
                  validators={[VALIDATOR_REQUIRED()]}
                >
                  <option value="">Seleccione un usuario</option>
                  {businessUser &&
                    businessUser.map((businessUser) => (
                      <option key={businessUser.id} value={businessUser.id}>
                        {businessUser.name} {businessUser.lastname}
                      </option>
                    ))}
                </Input>
              </div>
            )}
            {rol === "company" && (
              <div className="form-group">
                <Input
                  type="select"
                  id="company"
                  element="select"
                  onInput={inputHandler}
                  validators={[VALIDATOR_REQUIRED()]}
                  label="Compañía (solo para usuarios de tipo compañía)"
                >
                  <option value="">Seleccionar</option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Input>
              </div>
            )}
          </div>
          <div className="column">
            <div className="form-group">
              <Input
                type="text"
                id="email"
                label="Correo Electronico"
                placeholder="Correo electrónico"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                errorText="Este campo debe ser un correo electrónico válido"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="password"
                element="password"
                label="Contraseña"
                placeholder="Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_MINLENGTH(8)]}
                errorText="Este campo debe tener al menos 8 caracteres"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="repassword"
                element="password"
                label="Confirmar Contraseña"
                placeholder="Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_MINLENGTH(8)]}
                errorText="Este campo debe tener al menos 8 caracteres"
              />
            </div>
          </div>
          <div className="column">
            <img src="" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateUser
