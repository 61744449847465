import { React, useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { NavLink } from "react-router-dom"
import {
  mdiBookEdit,
  mdiDelete,
  mdiCheckboxBlankCircle,
  mdiCheckboxMarkedCircle
} from "@mdi/js"
import { AuthContext } from "shared/context/auth-context"
import axios from "axios"
import Table from "shared/components/DataTable/Table"
import Icon from "@mdi/react"
import Loader from "shared/components/UIElements/Loader"
import Pagination from "widgets/Pagination"

const Users = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [users, setUsers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState([])
  const [showModal, setShowModal] = useState("")
  const [isLoading, setIsLoading] = useState(null)
  const [userId, setUserId] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Usuarios"
    if (!auth.token) {
      return
    }
    getUsers()
  }, [auth.token, currentPage])

  const getUsers = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/users?page=${currentPage}`,
        method: "GET"
      })

      if (response.status === 200) {
        setUsers(response.data.users)
        setTotalPages(response.data.pages)
        setCurrentPage(response.data.currentPage)
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const openModal = (id) => {
    setShowModal("is-active")
    setUserId(id)
    setError(null)
  }

  const closeModal = () => {
    setShowModal("")
  }

  const deactivateUser = async (id) => {
    closeModal()
    setUserId(null)
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/users/${id}`,
        method: "DELETE"
      })

      if (response.status === 200) {
        getUsers()
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const headers = [
    {
      Header: "Nombre(s)",
      accessor: "name"
    },
    {
      Header: "Apellido(s)",
      accessor: "lastname"
    },
    {
      Header: "Correo",
      accessor: "email"
    },
    {
      Header: "Rol",
      accessor: "role"
    },
    {
      Header: "Empresa",
      accessor: "company.name"
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (row) => (
        <>
          {row.value ? (
            <Icon path={mdiCheckboxMarkedCircle} size={1} color="#73c67a" />
          ) : (
            <Icon path={mdiCheckboxBlankCircle} size={1} color="red" />
          )}
        </>
      )
    },
    {
      Header: "",
      accessor: "id",
      Cell: (row) => (
        <>
          <NavLink to={`/users/edit/${row.value}`}>
            <Icon path={mdiBookEdit} size={1} onClick={() => {}}>
              Edit
            </Icon>
          </NavLink>
          <Icon
            className="deactivate"
            path={mdiDelete}
            size={1}
            onClick={() => {
              openModal(row.value)
            }}
          >
            Delete
          </Icon>
        </>
      )
    }
  ]

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <div>
      <h1 className="title">Usuarios</h1>
      <div className="actions">
        <NavLink to="users/create">
          <button className="button is-normal">Registrar Usuario</button>
        </NavLink>
      </div>
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="card-mibus">
          {error && (
            <div className="columns">
              <div className="column">
                <div className="notification is-danger is-light">
                  Error: {error}
                </div>
              </div>
            </div>
          )}
          <Table dataHeaders={headers} dataBody={users} />
          <div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <div className={`modal ${showModal}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirmar</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => closeModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              Confirma que deseas eliminar este usuario, esta acción solo puede
              revertirla un administrador.
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-danger"
                onClick={() => deactivateUser(userId)}
              >
                Eliminar Usuario
              </button>
              <button className="button" onClick={() => closeModal()}>
                Cancelar
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users
