import React, { useState, useEffect } from "react"
import axios from "axios"

const Thinkific = () => {
  const [courses, setCourses] = useState([])
  const apiKey = "552d19c8fc78fefb934f6a05380b3293"
  const subdomain = "guillermo-s-site-818d"

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://api.thinkific.com/api/public/v1/courses",
          {
            headers: {
              "X-Auth-API-Key": apiKey,
              "X-Auth-Subdomain": subdomain,
              "Content-Type": "application/json"
            }
          }
        )
        setCourses(response.data) // assuming the response data is an array of courses
      } catch (error) {
        console.error("Error fetching courses:", error)
      }
    }

    fetchCourses()
  }, [])

  return (
    <div>
      <h1>List of Courses</h1>
      <ul>
        {courses.map((course) => (
          <li key={course.id}>{course.title}</li>
        ))}
      </ul>
    </div>
  )
}

export default Thinkific
