import React, { useState, useContext, useEffect, useCallback } from "react"
import { useHistory } from "react-router"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import axios from "axios"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"
import {
  VALIDATOR_REQUIRED,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH
} from "shared/utils/validator"

const EditEmployee = (props) => {
  const history = useHistory()
  const auth = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [companies, setCompanies] = useState([])
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState(null)
  const [formState, inputHandler, setFormData] = useForm(
    {
      company: {
        value: employee ? employee.company.id : "",
        isValid: true
      },
      name: {
        value: employee ? employee.name : "",
        isValid: true
      },
      lastname: {
        value: employee ? employee.lastname : "",
        isValid: true
      },
      code: {
        value: employee ? employee.code : "",
        isValid: true
      },
      email: {
        value: employee ? employee.email : "",
        isValid: true
      },
      password: {
        value: "",
        isValid: true
      },
      repassword: {
        value: "",
        isValid: true
      }
    },
    false
  )

  useEffect(() => {
    if (employee) {
      // Update the form state when the employee data changes
      setFormData(
        {
          company: {
            value: employee.company.id,
            isValid: true
          },
          name: {
            value: employee.name,
            isValid: true
          },
          lastname: {
            value: employee.lastname,
            isValid: true
          },
          code: {
            value: employee.code,
            isValid: true
          },
          email: {
            value: employee.email,
            isValid: true
          },
          password: {
            value: "",
            isValid: true
          },
          repassword: {
            value: "",
            isValid: true
          }
        },
        // Update the overall form validity based on your validation logic
        true
      )
    }
  }, [employee, setFormData])

  useEffect(() => {
    document.title = "Editar Empleado"
    if (!auth.token) {
      return
    }

    if (auth.user.role !== "company") {
      getCompanies()
    }
    getEmployee()
  }, [auth.token])

  const getEmployee = async () => {
    setIsLoading(true)
    const response = await axios({
      headers: {
        Authorization: `Bearer ${auth.token}`
      },
      baseURL: `${process.env.REACT_APP_API_URL}/employees/${props.match.params.id}`,
      method: "GET"
    })

    setEmployee(response.data.employee)
    setIsLoading(false)
  }

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: url,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const updateEmployee = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/employees/${props.match.params.id}`,
        method: "PUT",
        data: {
          name: formState.inputs.name.value,
          lastname: formState.inputs.lastname.value,
          email: formState.inputs.email.value,
          code: formState.inputs.code.value,
          company_id:
            auth.user.role !== "company"
              ? formState.inputs.company.value
              : auth.user.company.id,
          password: formState.inputs.password.value,
          repassword: formState.inputs.repassword.value
        }
      })

      setIsLoading(false)

      if (response.status === 201) {
        history.replace("/employees")
      }
    } catch (err) {
      setIsLoading(false)
      setErrors(err.response.data.error)
    }
  }

  const isFormValid = useCallback(() => {
    for (const inputId in formState.inputs) {
      if (formState.inputs[inputId].isValid === false) {
        return false
      }
    }
    return true
  }, [formState.inputs])

  return (
    <div>
      <div>
        <h1 className="title">Editar Empleado</h1>
        <div className="actions buttons">
          <button
            className="button is-success"
            disabled={!isFormValid()}
            onClick={updateEmployee}
          >
            Actualizar
          </button>
          <button onClick={history.goBack} className="button is-danger">
            Cancelar
          </button>
        </div>
      </div>
      {error && (
        <div className="notification is-danger is-light">Error: {error}</div>
      )}
      {errors && (
        <div className="notification is-danger is-light">
          {errors.map((error) => {
            return <li>{error}</li>
          })}
        </div>
      )}
      <div className="card-mibus">
        {isLoading && <Loader asOverlay />}
        {employee && (
          <div className="columns">
            <div className="column">
              {companies &&
                auth.user &&
                auth.user.role !== "company" &&
                (employee.company !== null ? (
                  <div className="form-group">
                    <div style={{ display: "none" }}>
                      {JSON.stringify(employee.company)}
                    </div>
                    <Input
                      id="company"
                      placeholder="Empresa"
                      label="Empresa"
                      className="form-control"
                      value={employee.company.id}
                      element="select"
                      onInput={inputHandler}
                      validators={[]}
                    >
                      <option value="">Seleccionar</option>
                      {companies.map((company) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        )
                      })}
                    </Input>
                  </div>
                ) : (
                  <div className="form-group">
                    <Input
                      id="company"
                      placeholder="Ruta"
                      label="Empresa"
                      className="form-control"
                      element="select"
                      onInput={inputHandler}
                      validators={[]}
                    >
                      <option value="">Seleccionar</option>
                      {companies.map((company) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        )
                      })}
                    </Input>
                  </div>
                ))}
              <div className="form-group">
                <Input
                  type="text"
                  id="name"
                  label="Nombre"
                  value={employee.name}
                  placeholder="Nombre"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El nombre es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="text"
                  id="lastname"
                  label="Apellido"
                  value={employee.lastname}
                  placeholder="Apellido"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El apellido es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="text"
                  id="code"
                  value={employee.code}
                  placeholder="Matrícula"
                  label="Matrícula"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="El  es obligatorio"
                  validators={[VALIDATOR_REQUIRED()]}
                />
              </div>
            </div>
            <div className="column">
              <div className="form-group">
                <Input
                  type="text"
                  id="email"
                  value={employee.email}
                  placeholder="Correo electrónico"
                  label="Correo electrónico"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="Debes colocar un email válido"
                  validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  id="password"
                  element="password"
                  placeholder="Contraseña (Solo llenar para cambiar la anterior)"
                  label="Contraseña (Solo llenar para cambiar la anterior)"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="La contraseña debe contener al menos 8 caracteres"
                  validators={[]}
                />
              </div>
              <div className="form-group">
                <Input
                  type="password"
                  id="repassword"
                  element="password"
                  placeholder="Confirmar Contraseña"
                  label="Confirmar Contraseña"
                  className="form-control"
                  onInput={inputHandler}
                  errorText="La contraseña debe contener al menos 8 caracteres"
                  validators={[]}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditEmployee
