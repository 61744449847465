import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "shared/hooks/formHook";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRED } from "shared/utils/validator";
import axios from "axios";
import Input from "shared/components/FormElements/Input";

const Reset = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    document.title = "Recuperar Contraseña";
  }, []);

  const sendToken = async () => {
    try {
      const response = await axios({
        baseURL: `${process.env.REACT_APP_API_URL}/users/send-token/`,
        method: "POST",
        data: {
          email: formState.inputs.email.value,
        },
      });

      if (response.status === 200) {
        setSuccess(
          "Se ha enviado un correo a su cuenta de correo con un enlace para restablecer su contraseña."
        );
        setError("");
      }
    } catch (err) {
      setSuccess("");
      setError("No se ha podido enviar el correo, por favor intente de nuevo.");
    }
  };

  return (
    <div className="container">
      <h1 className="title">Recuperar Contraseña My Tracking Bus</h1>
      {success && (
        <div className="notification is-success" style={{ width: "50%" }}>
          {success}
        </div>
      )}
      {error && (
        <div className="notification is-danger" style={{ width: "50%" }}>
          {error}
        </div>
      )}
      <div className="field">
        <label className="label">Correo Electrónico</label>
        <div className="control" style={{ width: "50%" }}>
          <Input
            id="email"
            className="input"
            type="text"
            placeholder="Correo Electrónico"
            validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRED()]}
            errorText="Este campo es obligatorio y debe ser un correo electrónico válido"
            onInput={inputHandler}
          />
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link" onClick={sendToken}>
            Enviarme el código
          </button>
        </div>
        <div className="control">
          <NavLink to="/">
            <button className="button is-link is-light">Cancelar</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Reset;
