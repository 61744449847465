import { React, useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { mdiBookEdit, mdiFileExcel } from "@mdi/js"
import { AuthContext } from "shared/context/auth-context"
import axios from "axios"
import Table from "shared/components/DataTable/Table"
import Icon from "@mdi/react"
import Loader from "shared/components/UIElements/Loader"

import "./Companies.css"

const Companies = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [companies, setCompanies] = useState([])
  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Compañías"
    if (!auth.token) {
      return
    }

    if (auth.user.role === "company") {
      history.replace("/panel")
    }

    getCompanies()
  }, [auth.token])

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "admin") {
      url = `${process.env.REACT_APP_API_URL}/companies`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: url,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const headers = [
    {
      Header: "ID",
      accessor: "_id"
    },
    {
      Header: "logo",
      accessor: "image",
      Cell: (row) => (
        <>
          {row.value && (
            <img
              src={`http://api.mytrackingbus.com/${row.value}`}
              alt="logo"
              height="30"
              width="30"
            />
          )}
        </>
      )
    },
    {
      Header: "Nombre",
      accessor: "name"
    },
    {
      Header: "Encargado",
      accessor: "userParent",
      Cell: (row) => (
        <>
          {row.value && row.value.name !== undefined && `${row.value.name} `}
          {row.value &&
            row.value.lastname !== undefined &&
            `${row.value.lastname}`}
        </>
      )
    },
    {
      Header: "",
      accessor: "id",
      Cell: (row) => (
        <>
          <NavLink to={`/companies/edit/${row.value}`}>
            <Icon path={mdiBookEdit} size={1}>
              Edit
            </Icon>
          </NavLink>
          <NavLink to={`/companies/view/${row.value}`}>
            <Icon path={mdiFileExcel} size={1}>
              View
            </Icon>
          </NavLink>
        </>
      )
    }
  ]

  return (
    <div>
      <h1 className="title">Compañías</h1>
      <div className="actions">
        <NavLink to="/companies/create">
          <button className="button is-normal">Registrar Empresa</button>
        </NavLink>
      </div>
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="card-mibus">
          {error && (
            <div className="notification is-danger is-light">
              Error: {error}
            </div>
          )}
          <Table dataHeaders={headers} dataBody={companies} />
        </div>
      </div>
    </div>
  )
}

export default Companies
