import React, { useMemo } from "react"
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination
} from "react-table"
import { mdiArrowDown, mdiArrowUp, mdiCircle } from "@mdi/js"
import GlobalFilter from "./GlobalFilter"
import Icon from "@mdi/react"
import "./Table.css"

const Table = (props) => {
  const dataHeaders = props.dataHeaders
  const dataBody = props.dataBody
  const dataCompanies = props.dataCompanies

  const columns = useMemo(() => dataHeaders, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns: columns,
      data: dataBody,
      initialState: { pageIndex: 0, pageSize: 100 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const reload = (event) => {
    props.cb(event.target.value)
  }

  return (
    <div>
      <div className="columns">
        <div className="column">
          {dataCompanies && (
            <div className="form-group">
              <div className="form-control">
                <label
                  style={{
                    fontWeight: "bold",
                    display: "block",
                    fontSize: "12px",
                    marginBottom: "5px"
                  }}
                >
                  Filtrar datos por Compañía
                </label>
                <select onChange={reload}>
                  <option value="all">Seleccionar Compañía</option>
                  {dataCompanies.map((company) => {
                    return (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
        </div>
        {props.hasDateFilter && (
          <div className="column">
            <label
              style={{
                fontWeight: "bold",
                display: "block",
                fontSize: "12px",
                marginBottom: "5px"
              }}
            >
              Buscar
            </label>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              hasDateFilter={props.hasDateFilter}
            />
          </div>
        )}
      </div>
      <div className="table-container">
        <table
          className="table is-fullwidth is-bordered is-striped"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((head) => (
              <tr {...head.getHeaderGroupProps()}>
                {head.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span className="sorted">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon path={mdiArrowDown} size={1} />
                        ) : (
                          <Icon path={mdiArrowUp} size={1} />
                        )
                      ) : (
                        <Icon path={mdiCircle} size={1} color={"#fff"} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps({
                    onClick: (e) =>
                      props.onRowClicked &&
                      props.onRowClicked(row.cells[0].row.original, e)
                  })}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
