import React, { useCallback, useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"

import Sidebar from "shared/components/Sidebar"
import ContentBody from "./ContentBody"

import Login from "login/pages/Login"
import Dashboard from "dashboard/pages/Dashboard"
import Companies from "companies/pages/Companies"
import CreateCompany from "companies/pages/CreateCompany"
import EditCompany from "companies/pages/EditCompany"
import ViewCompany from "companies/pages/ViewCompany"
import Users from "users/pages/Users"
import CreateUser from "users/pages/CreateUser"
import EditUser from "users/pages/EditUser"
import Routes from "routes/pages/Routes"
import CreateRoute from "routes/pages/CreateRoute"
import EditRoute from "routes/pages/EditRoute"
import EditRouteData from "routes/pages/EditRouteData"
import ViewRoute from "routes/pages/ViewRoute"
import Drivers from "drivers/pages/Drivers"
import EditDriver from "drivers/pages/EditDriver"
import Employees from "employees/pages/Employees"
import EditEmployee from "employees/pages/EditEmployee"
import Load from "excel/pages/Load"
import Import from "excel/pages/Import"
import CreateDriver from "drivers/pages/CreateDriver"
import CreateEmployee from "employees/pages/CreateEmployee"
import Reset from "login/pages/Reset"
import DoReset from "login/pages/DoReset"

//thinkific
import Thinkific from "thinkific/thinkific"

const Main = () => {
  const [token, setToken] = useState(false)
  const [user, setUser] = useState(false)

  const login = useCallback((user, token) => {
    setToken(token)
    setUser(user)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        user: user,
        token: token
      })
    )
  }, [])

  const logout = useCallback(() => {
    setToken(false)
    setUser(null)
    localStorage.removeItem("userData")
  }, [])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"))
    if (storedData && storedData.token) {
      login(storedData.user, storedData.token)
    }
  }, [login])

  const noSidebar = ["/login", "/reset-password", "/doreset"]

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        user: user,
        login: login,
        logout: logout
      }}
    >
      <Router>
        <Route
          render={({ location }) =>
            location.pathname === "/" ? null : noSidebar.some((route) =>
                location.pathname.includes(route)
              ) ? null : (
              <Sidebar />
            )
          }
        />
        <ContentBody>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/panel" component={Dashboard} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/users/create" component={CreateUser} />
            <Route exact path="/users/edit/:id" component={EditUser} />
            <Route exact path="/companies" component={Companies} />
            <Route exact path="/companies/create" component={CreateCompany} />
            <Route exact path="/companies/edit/:id" component={EditCompany} />
            <Route exact path="/companies/view/:id" component={ViewCompany} />
            <Route exact path="/routes" component={Routes} />
            <Route exact path="/routes/create" component={CreateRoute} />
            <Route exact path="/routes/edit/:id" component={EditRoute} />
            <Route
              exact
              path="/routes/edit/data/:id"
              component={EditRouteData}
            />
            <Route exact path="/routes/view/:id" component={ViewRoute} />
            <Route exact path="/drivers" component={Drivers} />
            <Route exact path="/drivers/create" component={CreateDriver} />
            <Route exact path="/drivers/edit/:id" component={EditDriver} />
            <Route exact path="/employees" component={Employees} />
            <Route exact path="/employees/create" component={CreateEmployee} />
            <Route exact path="/employees/edit/:id" component={EditEmployee} />
            <Route exact path="/load" component={Import} />
            <Route exact path="/import" component={Load} />
            <Route exact path="/reset-password" component={Reset} />
            <Route exact path="/doreset/:token" component={DoReset} />
            <Route exact path="/thinkific" component={Thinkific} />
          </Switch>
        </ContentBody>
      </Router>
    </AuthContext.Provider>
  )
}

export default Main
