import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import Input from "shared/components/FormElements/Input"
import { VALIDATOR_REQUIRED } from "shared/utils/validator"
import { useForm } from "shared/hooks/formHook"
import { AuthContext } from "shared/context/auth-context"
import ImageUpload from "shared/components/FormElements/ImageUpload"
import axios from "axios"

const CreateCompany = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [businessUsers, setBusinessUsers] = useState([])
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false
      },
      image: {
        value: "",
        isValid: false
      },
      userParent: {
        value: "",
        isValid: false
      }
    },
    false
  )

  useEffect(() => {
    document.title = "Registrar Empresa"

    if (!auth.token) {
      return
    }

    if (auth.user.role === "company") {
      history.replace("/panel")
    } else if (auth.user.role === "admin") {
      getBusinessUsers()
    }
  }, [auth.token])

  const getBusinessUsers = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/users/type/business`,
        method: "GET"
      })

      if (response.status === 200) {
        setBusinessUsers(response.data.users)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }

    setIsLoading(false)
  }

  const companySubmitHandler = async () => {
    setIsLoading(true)

    try {
      const formData = new FormData()
      formData.append("name", formState.inputs.name.value)
      formData.append("author", auth.user.id)
      formData.append(
        "userParent",
        auth.user.role === "admin"
          ? formState.inputs.userParent.value
          : auth.user.id
      )
      formData.append("image", formState.inputs.image.value)

      const response = await axios({
        baseURL: `${process.env.REACT_APP_API_URL}/companies`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + auth.token
        },
        data: formData
      })

      if (response.status === 200) {
        history.replace("/companies")
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message || err.message)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <h1 className="title">Registrar Empresa</h1>
      <div className="actions">
        <button className="button" onClick={companySubmitHandler}>
          Registrar
        </button>
        <button className="button is-danger" onClick={history.goBack}>
          Cancelar
        </button>
      </div>
      <div className="card-mibus">
        {error && (
          <div className="columns">
            <div className="column">
              <div className="notification is-danger is-light">{error}</div>
            </div>
          </div>
        )}
        <div className="columns">
          <div className="column">
            <div className="form-group">
              <Input
                label="Nombre"
                type="text"
                element="text"
                id="name"
                placeholder="Nombre de la compañía"
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El nombre de la compañía es obligatorio"
                onInput={inputHandler}
              />
            </div>
            {auth.user.role === "admin" && (
              <div className="form-group">
                <Input
                  label="Usuario Encargado"
                  type="select"
                  element="select"
                  id="userParent"
                  validators={[VALIDATOR_REQUIRED()]}
                  errorText="El usuario encargado es obligatorio"
                  onInput={inputHandler}
                >
                  <option value="">Seleccione un usuario</option>
                  {businessUsers.map((businessUser) => (
                    <option key={businessUser.id} value={businessUser.id}>
                      {businessUser.name}
                    </option>
                  ))}
                </Input>
              </div>
            )}
          </div>
          <div className="column">
            <ImageUpload
              center
              id="image"
              onInput={inputHandler}
              errorText="Selecciona una imagen"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCompany
