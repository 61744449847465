import React, { useState, useContext, useEffect } from "react";
import { mdiContentSave, mdiFileExcel } from "@mdi/js";
import { useForm } from "shared/hooks/formHook";
import { AuthContext } from "shared/context/auth-context";
import { useHistory } from "react-router-dom";
import ExcelUpload from "shared/components/FormElements/ExcelUpload";
import axios from "axios";
import Loader from "shared/components/UIElements/Loader";
import Icon from "@mdi/react";
import "./Load.css";

const Load = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [uploads, setUploads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [dataParsed, setDataParsed] = useState([]);
  const [formState, inputHandler] = useForm(
    {
      excel: {
        value: null,
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    document.title = "Importar";
    if (!auth.token) {
      return;
    }
    getUploads();
  }, [auth.token]);

  const openModal = () => {
    setShowModal("is-active");
  };

  const closeModal = () => {
    setShowModal("");
    setDataParsed([]);
    setTitle("");
    setMessage("");
  };

  const getUploads = async () => {
    setIsLoading(true);

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/import`,
        method: "GET",
      });

      if (response.status === 200) {
        setUploads(response.data.uploads);
      } else {
        setError(response.data.error);
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/");
      }
      setError(err.response.data.error);
    }
    setIsLoading(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const formData = new FormData();
    formData.append("excel", formState.inputs.excel.value);
    formData.append("userID", auth.user.id);
    console.log(formState.inputs.excel.value);
    try {
      const resp = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/import/`,
        method: "POST",
        mode: "no-cors",
        data: formData,
      });

      setIsLoading(false);

      if (resp.status === 201) {
        //history.replace("/posts");
        window.location.reload(false);
      }
    } catch (err) {
      setIsLoading(false);
      setError(
        err.response.data.message || "Something went wrong, please try again."
      );
    }
  };

  const submitParse = async (id) => {
    setIsLoading(true);
    try {
      const resp = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/import/parse/${id}`,
        method: "GET",
        mode: "no-cors",
      });

      if (resp.status === 201) {
        setMessage("Se han importado los datos correctamente");
        setDataParsed(resp.data.result);
        setTitle("Contenido Importado");
      } else {
        setMessage("Ocurrió un error al importar el archivo");
        setTitle("Error al importar");
      }
      openModal();
      setIsLoading(false);
    } catch (err) {
      setMessage("Ocurrió un error al importar el archivo");
      setTitle("Error al importar");
      openModal();
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h1 className="title">Importar Usuarios</h1>
      {isLoading && <Loader asOverlay />}
      <div style={{ overflow: "hidden", marginBottom: "20px" }}>
        <a
          style={{
            verticalAlign: "top",
            display: "inline-block",
            float: "left",
          }}
          href="https://docs.google.com/spreadsheets/d/1sRHfmxK5WRAspjAoEftxt89q0faKxQ8WnfnrZ7isTP0/edit?usp=sharing"
          target="_blank"
        >
          <Icon path={mdiFileExcel} size={3} color="green" />
        </a>
        <a href="https://docs.google.com/spreadsheets/d/1sRHfmxK5WRAspjAoEftxt89q0faKxQ8WnfnrZ7isTP0/edit?usp=sharing">
          <p>Archivo de muestra para importar</p>
        </a>
      </div>

      <div className="title-h1">
        <form onSubmit={submitHandler}>
          <div className="columns">
            <div className="column">
              {error && error}
              <ExcelUpload
                id="excel"
                onInput={inputHandler}
                errorText="Selecciona un archivo"
              />
              <button
                type="button"
                className="excelUpload"
                onClick={submitHandler}
              >
                Subir archivo
              </button>
            </div>
          </div>
        </form>
      </div>

      <div>
        <h1 className="title">Archivos cargados</h1>
        <div>
          {isLoading && <Loader asOverlay />}
          {uploads.map((upload) => (
            <div className="upload" key={upload.id}>
              {upload.name} - {upload.created}
              <span>
                <Icon
                  path={mdiContentSave}
                  size={1}
                  onClick={() => submitParse(upload.id)}
                />
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className={`modal ${showModal}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{title}</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => closeModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              <div>{message}</div>
              {dataParsed.length > 0 && (
                <div className="table-container">
                  <table className="table is-bordered is-fullwidth">
                    <tr>
                      <th>Nombre</th>
                      <th>Apellido</th>
                      <th>Email</th>
                    </tr>
                    {dataParsed.map((data) => (
                      <tr>
                        <td>{data.name}</td>
                        <td>{data.lastname}</td>
                        <td>{data.email}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </section>
            <footer className="modal-card-foot">
              <button className="button" onClick={() => closeModal()}>
                Aceptar
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Load;
