import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router"
import { AuthContext } from "shared/context/auth-context"
import { useForm } from "shared/hooks/formHook"
import { VALIDATOR_REQUIRED, VALIDATOR_MINLENGTH } from "shared/utils/validator"
import axios from "axios"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"

const CreateDriver = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false
      },
      email: {
        value: "",
        isValid: false
      },
      image: {
        value: "",
        isValid: false
      }
    },
    false
  )

  useEffect(() => {
    document.title = "Registrar Conductor"
    if (!auth.token) {
      return
    }
    getCompanies()
  }, [auth.token])

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: url,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const submitDriver = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        baseURL: `${process.env.REACT_APP_API_URL}/drivers`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + auth.token
        },
        data: {
          author: auth.user.id,
          company_id: formState.inputs.company.value,
          name: formState.inputs.name.value,
          lastname: formState.inputs.lastname.value,
          registration: formState.inputs.registration.value,
          license: formState.inputs.license.value,
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
          repassword: formState.inputs.repassword.value
        }
      })

      if (response.status === 200) {
        history.replace("/drivers")
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setError(err.response.data.message || err.message)
    }
  }

  return (
    <div>
      <h1 className="title">Registrar Conductor</h1>
      <div className="actions">
        <button className="button is-success" onClick={submitDriver}>
          Registrar
        </button>
        <button onClick={history.goBack} className="button is-danger">
          Cancelar
        </button>
      </div>
      <div className="card-mibus">
        {isLoading && <Loader asOverlay />}
        <div className="columns">
          <div className="column">
            {error && (
              <div className="columns">
                <div className="column">
                  <div className="notification is-danger is-light">
                    Error: {error}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {companies && (
              <div className="form-group">
                <Input
                  id="company"
                  placeholder="Empresa"
                  label="Empresa"
                  className="form-control"
                  element="select"
                  onInput={inputHandler}
                  validators={[VALIDATOR_REQUIRED()]}
                  errorText="La ruta es obligatoria"
                >
                  <option value="">Seleccionar</option>
                  {companies.map((company) => {
                    return (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    )
                  })}
                </Input>
              </div>
            )}
            <div className="form-group">
              <Input
                type="text"
                id="name"
                placeholder="Nombre"
                label="Nombre"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El nombre es obligatorio"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="lastname"
                placeholder="Apellido"
                label="Apellido"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El apellido es obligatorio"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="registration"
                placeholder="Matrícula"
                label="Matrícula"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="La matrícula es obligatoria"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="license"
                placeholder="Licencia"
                label="Licencia"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="La licencia es obligatoria"
              />
            </div>
          </div>
          <div className="column">
            <div className="form-group">
              <Input
                type="text"
                id="email"
                placeholder="Correo electrónico"
                label="Correo electrónico"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El correo electrónico es obligatorio"
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                id="password"
                element="password"
                placeholder="Contraseña"
                label="Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_MINLENGTH(8)]}
                errorText="La contraseña es obligatoria y debe tener al menos 8 caracteres"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="repassword"
                element="password"
                placeholder="Confirmar Contraseña"
                label="Confirmar Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="Confirma la contraseña"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateDriver
