import React, { useContext, useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router"
import Input from "shared/components/FormElements/Input"
import {
  VALIDATOR_REQUIRED,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL
} from "shared/utils/validator"
import { useForm } from "shared/hooks/formHook"
import { AuthContext } from "shared/context/auth-context"
import Loader from "shared/components/UIElements/Loader"
import axios from "axios"

const CreateEmployee = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false
      }
    },
    false
  )

  useEffect(() => {
    document.title = "Registrar Empleado"
    if (!auth.token) {
      return
    }
    if (auth.user.role !== "company") {
      getCompanies()
    }
  }, [auth.token])

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: url,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const isFormValid = useCallback(() => {
    for (const inputId in formState.inputs) {
      if (formState.inputs[inputId].isValid === false) {
        return false
      }
    }
    return true
  }, [formState.inputs])

  const submitHandler = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/employees/`,
        method: "POST",
        data: {
          author: auth.user.id,
          name: formState.inputs.name.value,
          lastname: formState.inputs.lastname.value,
          email: formState.inputs.email.value,
          code: formState.inputs.code.value,
          company_id: formState.inputs.company.value,
          password: formState.inputs.password.value,
          repassword: formState.inputs.repassword.value
        }
      })

      setIsLoading(false)

      if (response.status === 201) {
        history.replace("/employees")
      }
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      setError(err.response.data.message || "Algo salió mal")
    }
  }

  return (
    <div>
      <div>
        <h1 className="title">Registrar Empleado</h1>
        <div className="actions">
          <button
            className="button is-success"
            disabled={!isFormValid()}
            onClick={submitHandler}
          >
            Registrar
          </button>
          <button onClick={history.goBack} className="button is-danger">
            Cancelar
          </button>
        </div>
      </div>
      <div className="card-mibus">
        {isLoading && <Loader asOverlay />}
        <div className="columns">
          <div className="column">
            {error && (
              <div className="columns">
                <div className="column">
                  <div className="notification is-danger is-light">
                    Error: {error}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {companies && (
              <div className="form-group">
                <Input
                  id="company"
                  placeholder="Ruta"
                  label="Empresa"
                  className="form-control"
                  element="select"
                  onInput={inputHandler}
                  validators={[VALIDATOR_REQUIRED()]}
                  errorText="La empresa es obligatoria"
                >
                  {auth.user.role !== "company" && (
                    <>
                      <option value="">Seleccionar</option>
                      {companies.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </>
                  )}
                  {auth.user.role === "company" && (
                    <option value={auth.user.company.id}>
                      {auth.user.company.name}
                    </option>
                  )}
                </Input>
              </div>
            )}
            <div className="form-group">
              <Input
                type="text"
                id="name"
                label="Nombre"
                placeholder="Nombre"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El nombre es obligatorio"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="lastname"
                label="Apellido"
                placeholder="Apellido"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="El apellido es obligatorio"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="code"
                placeholder="Matrícula"
                label="Matrícula"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="La matrícula es obligatoria"
              />
            </div>
          </div>
          <div className="column">
            <div className="form-group">
              <Input
                type="text"
                id="email"
                placeholder="Correo electrónico"
                label="Correo electrónico"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_EMAIL()]}
                errorText="El correo electrónico es obligatorio y debe ser válido"
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                id="password"
                element="password"
                placeholder="Contraseña"
                label="Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED(), VALIDATOR_MINLENGTH(8)]}
                errorText="La contraseña es obligatoria y debe tener al menos 8 caracteres"
              />
            </div>
            <div className="form-group">
              <Input
                type="text"
                id="repassword"
                element="password"
                placeholder="Confirmar Contraseña"
                label="Confirmar Contraseña"
                className="form-control"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRED()]}
                errorText="Confirma la contraseña"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateEmployee
