import React, { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import NavLink from "react-router-dom/NavLink"
import { AuthContext } from "shared/context/auth-context"
import { mdiBookEdit, mdiDelete } from "@mdi/js"
import Table from "shared/components/DataTable/Table"
import Icon from "@mdi/react"
import Loader from "shared/components/UIElements/Loader"
import axios from "axios"

const Employee = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [employees, setEmployees] = useState([])
  const [companies, setCompanies] = useState([])
  const [showModal, setShowModal] = useState("")
  const [isLoading, setIsLoading] = useState(null)
  const [employeeId, setUserId] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Empleados"
    if (!auth.token) {
      return
    }
    if (auth.user.role !== "company") {
      getCompanies()
    }
    if (auth.user.role !== "business") {
      getEmployees()
    }
  }, [auth.token])

  const getCompanies = async () => {
    setIsLoading(true)

    let url
    if (auth.user.role === "business") {
      url = `${process.env.REACT_APP_API_URL}/companies/business/${auth.user.id}`
    } else {
      url = `${process.env.REACT_APP_API_URL}/companies/`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: url,
        method: "GET"
      })

      if (response.status === 200) {
        setCompanies(response.data.companies)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const getEmployees = async () => {
    setIsLoading(true)

    let endpoint = ""

    if (auth.user.role === "company") {
      endpoint = `/employees/company/${auth.user.company.id}`
    } else if (auth.user.role === "admin") {
      endpoint = `/employees`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}${endpoint}`,
        method: "GET"
      })

      if (response.status === 200) {
        setEmployees(response.data.employees)
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err)
    }
    setIsLoading(false)
  }

  const getEmployeesByCompany = async (companyId) => {
    if (companyId === "all") {
      getEmployees()
      return
    }

    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/employees/company/${companyId}`,
        method: "GET"
      })

      if (response.status === 200) {
        setEmployees(response.data.employees)
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      if (err.response.status === 401) {
        history.replace("/")
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const openModal = (id) => {
    setShowModal("is-active")
    setUserId(id)
    setError(null)
  }

  const closeModal = () => {
    setShowModal("")
  }

  const deactivateEmployee = async (id) => {
    closeModal()
    setUserId(null)
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        baseURL: `${process.env.REACT_APP_API_URL}/employees/${id}`,
        method: "DELETE"
      })

      if (response.status === 200) {
        getEmployees()
      } else {
        setError(response.data.error)
      }
    } catch (err) {
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const headers = [
    {
      Header: "Nombre(s)",
      accessor: "name"
    },
    {
      Header: "Apellidos",
      accessor: "lastname"
    },
    {
      Header: "Correo",
      accessor: "email"
    },
    {
      Header: "Compañía",
      accessor: "company.name"
    },
    {
      Header: "Código",
      accessor: "code"
    },
    {
      Header: "",
      accessor: "id",
      Cell: (row) => (
        <>
          <NavLink to={`/employees/edit/${row.value}`}>
            <Icon path={mdiBookEdit} size={1}>
              Edit
            </Icon>
          </NavLink>
          <Icon
            className="deactivate"
            path={mdiDelete}
            size={1}
            onClick={() => {
              openModal(row.value)
            }}
          >
            Delete
          </Icon>
        </>
      )
    }
  ]

  return (
    <div>
      <h1 className="title">Empleados</h1>
      <div className="actions">
        <NavLink to="/employees/create">
          <button className="button is-normal">Registrar Empleado</button>
        </NavLink>
      </div>
      <div>
        {isLoading && <Loader asOverlay />}
        <div className="card-mibus">
          {error && (
            <div className="columns">
              <div className="column">
                <div className="notification is-danger is-light">
                  Error: {error}
                </div>
              </div>
            </div>
          )}
          <Table
            dataCompanies={
              auth.user && auth.user.role !== "company" ? companies : null
            }
            cb={getEmployeesByCompany}
            dataHeaders={headers}
            dataBody={employees}
          />
        </div>
      </div>

      <div className={`modal ${showModal}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirmar</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => closeModal()}
              ></button>
            </header>
            <section className="modal-card-body">
              Confirma que deseas eliminar este empleado, esta acción solo puede
              revertirla un administrador.
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-danger"
                onClick={() => deactivateEmployee(employeeId)}
              >
                Eliminar Empleado
              </button>
              <button className="button" onClick={() => closeModal()}>
                Cancelar
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Employee
