import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "shared/hooks/formHook";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRED } from "shared/utils/validator";
import axios from "axios";
import Input from "shared/components/FormElements/Input";

const DoReset = (props) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [formState, inputHandler] = useForm(
    {
      password: {
        value: "",
        isValid: false,
      },
      confirmPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    document.title = "Recuperar Contraseña";
  }, []);

  const resetPassword = async () => {
    try {
      const response = await axios({
        baseURL: `${process.env.REACT_APP_API_URL}/users/reset-password/`,
        method: "POST",
        data: {
          password: formState.inputs.password.value,
          confirmPassword: formState.inputs.confirmPassword.value,
          token: props.match.params.token,
        },
      });

      if (response.status === 200) {
        setSuccess(response.data.message);
        setError("");
      }
    } catch (err) {
      setSuccess("");
      setError(err.response.data.message);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Recuperar Contraseña My Tracking Bus</h1>
      {success && (
        <div className="notification is-success" style={{ width: "50%" }}>
          {success}
          <NavLink to="/login">Iniciar sesión</NavLink>
        </div>
      )}
      {error && (
        <div className="notification is-danger" style={{ width: "50%" }}>
          {error}
        </div>
      )}
      <div className="field">
        <label className="label">Nueva Contraseña</label>
        <div className="control" style={{ width: "50%" }}>
          <Input
            id="password"
            className="input"
            type="text"
            placeholder="Contraseña"
            validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRED()]}
            errorText="Este campo es obligatorio y debe ser un correo electrónico válido"
            onInput={inputHandler}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Confirmar Nueva Contraseña</label>
        <div className="control" style={{ width: "50%" }}>
          <Input
            id="confirmPassword"
            className="input"
            type="text"
            placeholder="Contraseña"
            validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRED()]}
            errorText="Este campo es obligatorio y debe ser un correo electrónico válido"
            onInput={inputHandler}
          />
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link" onClick={resetPassword}>
            Enviar nueva contraseña
          </button>
        </div>
        <div className="control">
          <NavLink to="/">
            <button className="button is-link is-light">Cancelar</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default DoReset;
