import { React, useState } from "react";
import DatePicker from "react-datepicker";

import "./GlobalFilter.css";
import "react-datepicker/dist/react-datepicker.css";

const GlobalFilter = ({ filter, setFilter, hasDateFilter }) => {
  const [date, setDate] = useState("");
  const [formatDate, setFormatDate] = useState("");

  const handleDateChange = (date) => {
    setDate(date);
    //setFormatDate(date.toISOString().split("T")[0]);
    setFilter(date.toISOString().split("T")[0]);
  };

  return (
    <div className="form-control">
      {hasDateFilter && (
        <DatePicker
          className="datepicker"
          selected={date}
          onChange={handleDateChange}
        />
      )}
      <p className={`search ${hasDateFilter ? "has-date" : ""}`}>
        <input
          placeholder="Buscar"
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          type="text"
        />
      </p>
    </div>
  );
};

export default GlobalFilter;
